
import {defineComponent, reactive, toRefs, onBeforeMount, onMounted, watch} from 'vue'
import axios from '@/api/http.js';
import {ElMessageBox} from 'element-plus';
import {ElLoading} from 'element-plus';
import {useRoute, onBeforeRouteUpdate} from 'vue-router'

export default defineComponent({
  name: '',
  setup() {
    const route = useRoute();
    const data = reactive({
      title: "",
      iframeSrc: null,
    })

    const refData = toRefs(data);
    const getData = () => {
      console.log("====route!! =====", route)
      console.log("====route!! =====", route.path)
      console.log("====query!! =====", route.query)
      if (route.query.type == '') {
        // data['iframeSrc'] = route.query.url;
        let iframe = document.getElementById('myIframe')
        iframe['src'] = route.query.url;
        // data.iframeSrc = 'https://www.surveymonkey.com/r/JB5LZRC?session_name=&email=glenda.juele@thecxapp.com&firstname=Glenda&lastname=Juele'
      } else {
        const loading = ElLoading.service({
          lock: true,
        })
        let type = route.query.type
        if (type) {
          type = type['replaceAll']("'", '');
        }
        axios.request({
          method: 'PATCH',
          url: '/virtual/getExternalUrl',
          data: {
            type: type || '',
            meetingId: 'a7yyxczuty0qpjo2393gmyad',
          }
        }).then(res => {
          let iframe = document.getElementById('myIframe')
          iframe['src'] = res.data.link
          // data['iframeSrc'] = res.data.link;
          loading.close();
        }).catch(err => {
          console.log(err);
          loading.close();
        })
      }
    }
    onMounted(()=>{
      getData();
    })
    onBeforeRouteUpdate((to) => {
      console.log("====router update =====")
      console.log('to!!', to);
      if (to.query.type) {
        console.log("==== request api =====")
        const loading = ElLoading.service({
          lock: true,
        })
        let type = to.query.type
        if (type) {
          type = type['replaceAll']("'", '');
        }
        console.log("====type!!! =====",type)
        axios.request({
          method: 'PATCH',
          url: '/virtual/getExternalUrl',
          data: {
            type: type || '',
            meetingId: 'a7yyxczuty0qpjo2393gmyad',
          }
        }).then(res => {
          console.log('res:', res);
          // console.log('showIframeres.data.link:', res.data.link);
          let iframe = document.getElementById('myIframe');
          iframe['src'] = res.data.link;
          // data['iframeSrc'] = res.data.link;
          loading.close();
        }).catch(err => {
          console.log(err);
          loading.close();
        })
      }else{
        console.log("==== redirect url =====", to.query.url)
        if(to.query.url){
          let iframe = document.getElementById('myIframe');
          iframe['src'] = to.query.url;
        }

        // data['iframeSrc'] = to.query.url;
      }
    });
    return {
      ...refData,
    }

  }
});
